body {
  background-color: #f7f7f7;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

header {
  border-bottom: 1px solid #DCDCDC;
  padding: 13px 0 20px;
  background-color: white;
}

.header-logo {
  width: 250px;
  margin: 0 auto;
}

.footer {
  z-index: 500;
  margin-top: 100px;
  height: 200px;
  background-color: #eee;

  .main-menu {
    margin-top: 30px;
  }

  .main-menu-title {
    font-size: 18px;
    color: #5c5c5c;
  }

  .main-menu-body {
    font-size: 14px;
    line-height: 25px;
  }

  .main-menu-body a {
    color: #5c5c5c;
  }
}

.landscape {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.portrait {
  max-height: 100%;
  width: auto;
  height: 100%;
}