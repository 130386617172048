#article-new {
  background-color: unset;
  font-family: "proxima-nova", sans-serif;

  .container {
    max-width: 996px;
  }

  .scroll-progress-bar {
    background-color: #E1EFFF;
    height: 6px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    div {
      background-color: #2394FC;
      height: 6px;
      transition: all linear 0.1s;
      width: 0;
    }
  }

  header {
    background-color: unset;
    border: 0;

    .header-logo {
      padding: 8px 17px;
    }
  }

  .header-background {
    background-color: rgba(#4D92FB, 0.05);
    height: 620px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &-img {
      &-left {
        bottom: 0;
        left: 0;
        position: absolute;
      }

      &-right {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }
  }

  h1 {
    color: #0C1929;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 56px;
    margin-bottom: 40px;
    text-align: center;
    max-width: 592px;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 32px;
    padding-top: 64px;
    text-transform: capitalize;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 16px;
    padding-top: 32px;
    text-transform: uppercase;
  }

  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .quicklinks {
    margin-bottom: 40px;

    a {
      color: #2394FC;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      margin-right: 20px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &::after {
        background-color: #7b92ad;
        border-radius: 50%;
        bottom: 0;
        content: ' ';
        height: 4px;
        margin: auto;
        position: absolute;
        right: -12px;
        top: 0;
        width: 4px;
      }

      &:last-child::after {
        content: unset;
      }
    }
  }

  .hero {
    margin-bottom: 112px;

    &-img {
      border-radius: 8px;
      max-width: 996px;
      overflow: hidden;

      img {
        border-radius: 8px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .main {
    .container {
      display: flex;
    }

    .sidebar {
      height: max-content;
      margin: 0 53px 53px 0;
      max-width: 250px;
      position: sticky;
      top: 18px;

      &-contents-scroll {
        max-height: 65vh;
        overflow: auto;
      }
  
      &-table-contents {
        border-radius: 8px;
        border: 1px solid #D8D8D8;
        color: #0C1929;
        margin-bottom: 40px;
        padding: 24px;
    
        &-headline {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 16px;
        }
    
        &-links {
          display: flex;
          flex-direction: column;
        }
    
        &-link {
          background-image: url('data:image/svg+xml;charset=utf-8,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="%230C1929" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          background-position: 96% center;
          background-repeat: no-repeat;
          border-bottom: 1px dotted #D8D8D8;
          color: #0C1929;
          font-size: 12px;
          padding: 16px 40px 16px 0;
    
          &:last-child {
            border-bottom: unset;
          }
    
          &-active {
            background-image: url('data:image/svg+xml;charset=utf-8,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="%232D99FF" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            color: #2D99FF;
            font-weight: bold;
          }
        }
      }
  
      &-subscription {
        background-color: #2394FC;
        background-image: url('/assets/images/article/subscription-bg.png');
        background-position: -43px 130px;
        background-repeat: no-repeat;
        border-radius: 8px;
        color: white;
        padding: 34px 16px 24px;
    
        &-headline {
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
        }
    
        &-subtitle {
          font-size: 14px;
          line-height: 18px;
        }
    
        &-form {
          display: flex;
    
    
          input {
            border: 0;
            border-radius: 4px 0 0 4px;
            font-size: 13px;
            height: 48px;
            max-width: 170px;
            outline: 0;
            padding: 0 16px;
            width: 100%;
          }
    
          button {
            align-items: center;
            background-color: #0C1929;
            border: 0;
            border-radius: 0 4px 4px 0;
            display: flex;
            height: 48px;
            justify-content: center;
            outline: 0;
            width: 48px;
            cursor: pointer;
          }
        }
        .alert {
          font-size: 12px;
          padding: 5px 10px;
          top: -25px;
          width: 218px;
          text-align: center;
        }
      }
    }
  
    &-content {
      color: #0C1929;
      margin-top: -9px;
      max-width: 615px;
      overflow-wrap: break-word;
      padding-bottom: 64px;

      section {
        &>:last-child {
          margin-bottom: 0;
        }
      }

      &>p:first-child::first-letter {
        float: left;
        font-size: 64px;
        font-weight: bold;
        line-height: 68px;
        margin-right: 8px;
      }

      ul, ol {
        font-size: 18px;
        line-height: 42px;
        margin: 0 0 40px 0;
      }

      ul {
        list-style: none;
        padding: 0;

        & > li {
          &::before {
            color: #2394FC;
            content: "\2022";
            display: inline-block; 
            font-weight: bold;
            margin-right: 16px;
          }
        }
      }
  
      img {
        width: 100%;
      }

      .paragraph-sm {
        color: #8098B5;
        font-size: 13px;
        line-height: 20px;
        margin: 0;
      }

      h2#table-of-contents {
        background-color: #F2F9FF;
        border-radius: 8px 8px 0 0;
        font-size: 16px;
        line-height: 24px;
        margin: 64px 0 0 0;
        padding: 40px 40px 16px 40px;
        text-transform: uppercase;
      }

      .table-of-contents {
        background-color: #F2F9FF;
        border-radius: 0 0 8px 8px;
        padding: 0 40px 40px 40px;

        ul {
          margin: 0;

          li {
            &::before {
              content: '';
              margin: 0;
            }
          }
        }

        a {
          color: #2394FC;
          font-size: 20px;
          font-weight: bold;
          line-height: 48px;
          margin-bottom: 10px;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }  
  }

  .related-articles {
    background-color: rgba(#4D92FB, 0.1);
    padding: 80px 0;

    h3 {
      margin-bottom: 32px;
      margin-top: 0;
    }

    h4 {
      color: #0C1929;
      margin-bottom: 0;
    }

    .card {
      &-img {
        height: 252px;
        margin-bottom: 24px;
        overflow: hidden;
        padding-top: 0 !important;

        img {
          border-radius: 4px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .title,
  .quicklinks,
  .hero,
  .main {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1440px) {
  #article-new {
    .related-articles {
      .container {
        max-width: 1200px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #article-new {
    .sidebar {
      margin: 0 32px 32px 0;
    }

    .related-articles {
      .card-img {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #article-new {
    .related-articles {
      .container-fluid {
        max-width: 960px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  body {
    padding-bottom: 120px;
  }
  #article-new {
    .hero {
      margin-bottom: 40px;
    }

    .main-content {
      max-width: 100%;
    }

    .main {
      .sidebar {
        position: fixed;
        width: 100%;
        margin: 0;
        max-width: 100%;
        left: 0;
        top: -130px;
        z-index: 1;
        -o-transition: .4s top cubic-bezier(.3, .73, .3, .74);
        -moz-transition: .4s top cubic-bezier(.3, .73, .3, .74);
        -webkit-transition: .4s top cubic-bezier(.3, .73, .3, .74);
        transition: .4s top cubic-bezier(.3, .73, .3, .74);

        &.show {
          top: 6px;
        }
      }
      .sidebar-subscription {
        border-radius: 0;
        padding: 15px;
      }
      .sidebar-subscription-headline {
        font-size: 16px;
      }
      .sidebar-subscription-form {
        input {
          max-width: 100%;
        }
        input, button {
          height: 38px;
        }
      }
    }

    .related-articles {
      padding: 40px 15px;

      .container-fluid {
        max-width: 720px;
      }

      .card {
        margin-bottom: 32px;

        &-img {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  #article-new {
    h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      padding-top: 40px;
    }

    h3 {
      padding-top: 16px;
      font-size: 20px;
      margin-top: 0;
    }

    .container {
      padding-left: 9px;
      padding-right: 9px;
    }

    h1 {
      font-size: 28px;
      letter-spacing: -1px;
      line-height: 32px;
      margin-bottom: 32px;
    }

    .quicklinks a {
      line-height: 25px;
    }

    .hero {
      .container {
        padding-left: 1px;
        padding-right: 1px;
      }

      &-img {
        padding-top: 83.6734693877551% !important;
      }
    }

    .main-content {
      padding-bottom: 32px;

      h2#table-of-contents,
      .table-of-contents {
        border-radius: 0;
        position: relative;

        &::before {
          background-color: #F2F9FF;
          content: ' ';
          height: 100%;
          left: -24px;
          position: absolute;
          top: 0;
          width: 24px;
        }

        &::after {
          background-color: #F2F9FF;
          content: ' ';
          height: 100%;
          right: -24px;
          position: absolute;
          top: 0;
          width: 24px;
        }
      }

      h2#table-of-contents {
        font-size: 16px;
        line-height: 42px;
        padding: 24px 0 0;
        margin: 40px 0 0 0;
      }

      .table-of-contents {
        padding: 0 0 24px;

        a {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .related-articles {
      .container {
        margin: auto;
        max-width: 282px;
        text-align: center;
      }

      h3 {
        margin-bottom: 24px;
      }

      .row > div:last-child .card {
        margin-bottom: 0;
      }

      .card {
        h4 {
          margin: 0 3px;
        }
      }
    }
  }
}

@media only screen and (max-height: 980px) {
  #article-new {
    .main {
      .sidebar {
        &-table-contents {
          padding: 16px;

          &-headline {
            margin-bottom: 8px;
          }

          &-link {
            padding: 10px 40px 10px 0;
          }
        }
      }
    }
  }
}
