#home {
  .main-title {
    color: #0c192a;
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .article-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
    margin-top: 40px;
    transition: .5s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    position: relative;
  }

  .article-item:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
  }

  .article-item-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    height: 300px;
  }

  .article-item-content-wrapper {
    display: flex;
    margin-right: 30px;
    flex-direction: column;
  }

  .article-item-content-cta {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }


  .article-item-content-category {
    margin-left: 30px;
    margin-top: 25px;
    font-size: 12px;
    color: #999;
    font-weight: 600;
  }

  .article-item-content-title {
    margin-left: 30px;
    margin-top: 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4em;
  }

  .article-item-content-body {
    margin-top: 25px;
    margin-left: 30px;
    padding-bottom: 50px;
  }

  .article-item-read-more-link {
    font-weight: 600;
    text-align: right;
  }
}

@media (min-width: 992px) {
  #home {
    .main-title {
      margin-top: 50px;
    }

    .article-list {
      margin-top: 50px;
    }

    .article-item {
      flex-direction: row;
      height: 400px;
    }

    .article-item-image-wrapper {
      width: 390px;
      height: auto;
    }

    .article-item:hover {
      transform: scale(1.05);
    }
  }

}

@media (min-width: 1200px) {
  #home {
    .article-item {
      height: 355px;
    }
  }
}
